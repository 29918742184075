import axios from "utils/APIv2";

export const getReceivablesAccounts = async (page, limit, accountName) => {
  const params = {
    pageNo: page,
    limit: "100",
    accountName: accountName,
  };
  const res = await axios.get(`/cashClassification/non-trade-receivables`, {
    params: params,
  });
  return res.data;
};

export const getPayablesAccounts = async (page, limit, accountName) => {
  const params = {
    pageNo: page,
    limit: "100",
    accountName: accountName,
  };
  const res = await axios.get(`/cashClassification/non-controllable-payables`, {
    params: params,
  });
  return res.data;
};

export const getBanks = async (page, limit, accountName) => {
  const params = {
    pageNo: page,
    limit: "100",
    accountName: accountName,
  };
  const res = await axios.get(`/cashClassification/bank`, {
    params: params,
  });
  return res.data;
};

export const getAllSelectedAccounts = async () => {
  const res = await axios.get(
    `/cashClassification/nonTradeAndNonControllables`
  );
  return res.data;
};
export const getAllIncomeAccounts = async () => {
  console.log('cash-classification-income')
  const res = await axios.get(
    `/cashClassification/cash-classification-income`
  );
  return res.data;
};
export const getAllExpenseAccounts = async () => {
  console.log('cash-classification-expense')
  const res = await axios.get(
    `/cashClassification/cash-classification-expense`
  );
  return res.data;
};
export const getAllrecurringAccounts = async (payload) => {
  console.log('cash-classification-recuring')
  const res = await axios.get(
    `/cashClassification/cash-classification-recuring`
  );
  return res.data;
};
export const postAllSelectedAccounts = async (payload) => {
  return await axios.post(
    `/cashClassification/nonTradeAndNonControllables`,
    payload
  );
};
export const postAllSelectedRecurringAccounts = async (payload) => {
  return await axios.post(
    `/cashClassification/nonTradeAndNonControllables/recuring`,
    payload
  );
};

export const deleteCashClassificationBudgeting = async (payload) => {
  return await axios.delete(
    `/cashClassification/cash-budgeting-delete`, {
    params: payload, // Send payload as query parameters
  }
  );
};

export const addCashClassificationBudgeting = async (payload) => {
  return await axios.post(
    `/cashClassification/cash-budgeting-add`,
    payload
  );
};

export const getCashClassificationBudgetingExpenseList = async (payload) => {
  return await axios.get(
    `/cashClassification/cash-budgeting-expense-list`,
    payload
  );
};

export const getCashClassificationBudgetingIncomeList = async (payload) => {
  return await axios.get(
    `/cashClassification/cash-budgeting-income-list`,
    payload
  );
};