import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import Loader from "ui/Loader";
// import QuickbooksConnection from "screens/setup/QuickbooksConnection";
import Connection from "screens/setup/Connection";
import GeneralSettingsPage from "screens/setup/GeneralSettings";
import UserManagementPage from "screens/setup/user-management";
import CashClassificationPage from "screens/setup/CashClassification";
import CustomerNotificationPage from "screens/setup/customer-notification/CustomerNotification";
import DisputeManagementPage from "screens/setup/dispute-management";
import SupplierPaymentPage from "screens/setup/supplier-payment";
import { Route_URLs } from "./routesURLs";
import Analytics from "screens/operations/analytics";
import TenantAnalytics from "screens/operations/analytics/analytics";
import DigitalPayments from "screens/setup/digital-payments";
import BillingAddress from "screens/customer/BillingAddress";
import Transactions from "screens/customer/Transactions";
import SuccessPaymentPage from "screens/customer/successPaymentPage";
import PaymentProcessing from "screens/customer/PaymentProcessing";
import RedirectPage from "screens/customer/RedirectPage";
import PaymentPage from "screens/dashboard/customer-dashboard/PaymentPage";
import ReceivableTransactions from "screens/actuals/popover/ReceivableTransactionPage";

const LoginPage = lazy(() => import("screens/account/LoginPage"));
const ForgotPasswordPage = lazy(() =>
  import("screens/account/ForgotPasswordPage")
);
const CreateAccountPage = lazy(() =>
  import("screens/account/CreateAccountPage")
);
const AccountVerificationPage = lazy(() =>
  import("screens/account/AccountVerificationPage")
);

const SetNewPasswordPage = lazy(() =>
  import("screens/account/SetNewPasswordPage")
);

const MfaScreen = lazy(() => import("screens/account/MfaScreen"));

const DashboardPage = lazy(() => import("screens/dashboard/DashboardPage"));
const CustomerDashboardPage = lazy(() =>
  import("screens/dashboard/customer-dashboard")
);
const ConfirmPaymentCustomerDashboardPage = lazy(() =>
  import("screens/dashboard/customer-dashboard/ConfirmPaymentPage")
);
const DashboardCustomisePage = lazy(() =>
  import("screens/dashboard/DashboardCustomisePage")
);
const NetCashPage = lazy(() => import("screens/NetCashPage"));
const ForecastReceivablePage = lazy(() =>
  import("screens/forecast/ForecastReceivablePage")
);
const ForecastPayablePage = lazy(() =>
  import("screens/forecast/ForecastPayablePage")
);
const ForecastCollectionPage = lazy(() =>
  import("screens/forecast/ForecastCollectionPage")
);
const ScenarioPage = lazy(() => import("screens/scenario/ScenarioPage"));
const OrderInvoicesPage = lazy(() =>
  import("screens/orders/OrderInvoicesPage")
);
const OrderOverviewPage = lazy(() =>
  import("screens/orders/OrderOverviewPage")
);
const ActualsReceivablesPage = lazy(() =>
  import("screens/actuals/ActualsReceivablesPage")
);
const ActualsCollectionsPage = lazy(() =>
  import("screens/actuals/ActualsCollectionsPage")
);
const ActualsPayablesPage = lazy(() =>
  import("screens/actuals/ActualsPayablesPage")
);
// const NonTradePayablesPage = lazy(() =>
//   import("screens/actuals/NonTradePayablesPage")
// );
const NonControllablePayablesPage = lazy(() =>
  import("screens/actuals/NonControllablePayablesPage")
);
const NonTradeReceivablePage = lazy(() =>
  import("screens/actuals/NonTradeReceivablePage")
);
const ConfirmPaymentPage = lazy(() =>
  import("screens/actuals/ConfirmPaymentPage")
);
const PaymentsHistoryPage = lazy(() =>
  import("screens/actuals/PaymentsHistoryPage")
);

const ProfilePage = lazy(() => import("screens/account/ProfilePage"));

const CustomersListing = lazy(() =>
  import("screens/customer/CustomersListingPage")
);

const SuppliersListing = lazy(() =>
  import("screens/supplier/SuppliersListing")
);

const TearSheetPageCustomer = lazy(() =>
  import("screens/TearSheetPageCustomer")
);

const TearSheetPageSupplier = lazy(() =>
  import("screens/TearSheetPageSupplier")
);
const CustomerSingUpForm = lazy(() =>
  import("screens/account/CustomerSingUpForm")
);

const NotificationsPage = lazy(() => import("screens/NotificationsPage"));
const OptimizationPage = lazy(() => import("screens/PayablesOptimizationPage"));
const NontradePage = lazy(() => import("screens/NonTradeReceivables"));

const ClientEmailTemplateCreation = lazy(() =>
  import("screens/setup/customer-email-template")
);
const ClientEmailTemplatesList = lazy(() =>
  import("screens/setup/customer-email-template/listEmailTemplates")
);

/* operations dashboard */
const AdminLogin = lazy(() =>
  import("screens/operations/login/AdminUserLoginPage")
);
const OperationsDashboard = lazy(() => import("screens/operations/dashboard"));
const TenantList = lazy(() => import("screens/operations/login/tenantList"));
const CustomerList = lazy(() =>
  import("screens/operations/login/customerList")
);
const SessionList = lazy(() => import("screens/operations/login/sessionList"));

const UserList = lazy(() => import("screens/operations/users"));
const DataLoads = lazy(() => import("screens/operations/dataLoads"));
const Subscriptions = lazy(() => import("screens/operations/subscriptions"));
const FeatureSwitches = lazy(() => import("screens/operations/featureSwitch"));

const GuestPayment = lazy(() => import("screens/customer/GuestPayment"));
const GuestSuccessPaymentPage = lazy(()=> import('screens/customer/GuestPaymentSuceesPage'))
const AppRoutes = ({ isAuthenticated, isAdminAuthenticated }) => {
  return (
    <Router>
      <Suspense fallback={<Loader displayCard={true} />}>
        <Switch>
          {/* Public Routes */}
          <Route exact path="/">
            {isAuthenticated ? (
              <Redirect to={Route_URLs.dashboard} />
            ) : (
              <LoginPage />
            )}
          </Route>
          <Route exact path={Route_URLs.admin_login}>
            {isAdminAuthenticated ? (
              <Redirect to={Route_URLs.tenantList} />
            ) : (
              <AdminLogin />
            )}
          </Route>
          <Route exact path={Route_URLs.operationDashboard}>
            <OperationsDashboard />
          </Route>
          <Route exact path={Route_URLs.tenantList}>
            <TenantList />
          </Route>
          <Route exact path={Route_URLs.customerList}>
            <CustomerList />
          </Route>
          <Route exact path={Route_URLs.sessionList}>
            <SessionList />
          </Route>
          <Route exact path={Route_URLs.adminUsers}>
            <UserList />
          </Route>
          <Route exact path={Route_URLs.analytics}>
            <Analytics />
          </Route>
          <Route path={`${Route_URLs.analytics}/:param`}>
            <TenantAnalytics />
          </Route>
          <Route exact path={Route_URLs.dataLoads}>
            <DataLoads />
          </Route>
          <Route exact path={Route_URLs.subscriptions}>
            <Subscriptions />
          </Route>
          <Route exact path={Route_URLs.featureSwitches}>
            <FeatureSwitches />
          </Route>
          <Route exact path={Route_URLs.signin}>
            <LoginPage />
          </Route>
          <Route exact path={Route_URLs.createaccount}>
            <CreateAccountPage />
          </Route>
          <Route exact path={Route_URLs.forgotpassword}>
            <ForgotPasswordPage />
          </Route>
          <Route exact path={Route_URLs.accountverify}>
            <AccountVerificationPage />
          </Route>
          <Route exact path={Route_URLs.passwordreset}>
            <SetNewPasswordPage />
          </Route>
          <Route exact path={Route_URLs.mfascreen}>
            <MfaScreen />
          </Route>
          <Route exact path={Route_URLs.customer_signup}>
            <CustomerSingUpForm />
          </Route>

          {/* Private Routes */}
          <Route exact path={Route_URLs.dashboard}>
            <DashboardPage />
          </Route>
          <Route exact path={Route_URLs.dashboard_customer}>
            {isAuthenticated ? <CustomerDashboardPage /> : <LoginPage />}
          </Route>
          <Route exact path={Route_URLs.dashboard_customer_payment_confirmed}>
            <ConfirmPaymentCustomerDashboardPage />
          </Route>
          <Route exact path={Route_URLs.dashboard_customize}>
            <DashboardCustomisePage />
          </Route>
          <Route exact path={Route_URLs.netcash}>
            <NetCashPage />
          </Route>
          <Route exact path={Route_URLs.forecast_receivables}>
            <ForecastReceivablePage />
          </Route>
          <Route exact path={Route_URLs.forecast_payables}>
            <ForecastPayablePage />
          </Route>
          <Route exact path={Route_URLs.forecast_collections}>
            <ForecastCollectionPage />
          </Route>
          <Route exact path={Route_URLs.forecast_optimization}>
            <OptimizationPage />
          </Route>
          <Route exact path={Route_URLs.scenario}>
            <ScenarioPage />
          </Route>
          <Route exact path={Route_URLs.orders_invoices}>
            <OrderInvoicesPage />
          </Route>
          <Route exact path={Route_URLs.orders_overview}>
            <OrderOverviewPage />
          </Route>
          <Route exact path={Route_URLs.actuals_receivables}>
            <ActualsReceivablesPage />
          </Route>
          <Route exact path={Route_URLs.actuals_collections}>
            <ActualsCollectionsPage />
          </Route>
          <Route exact path={Route_URLs.actuals_payables}>
            <ActualsPayablesPage />
          </Route>
          <Route exact path={Route_URLs.actuals_payments}>
            <PaymentsHistoryPage />
          </Route>
          <Route exact path={Route_URLs.actuals_payment_confirmed}>
            <ConfirmPaymentPage />
          </Route>
          <Route exact path={Route_URLs.emailTemplateCreation}>
            <ClientEmailTemplateCreation />
          </Route>
          <Route exact path={Route_URLs.emailTemplatesList}>
            <ClientEmailTemplatesList />
          </Route>
          {/* <Route exact path={Route_URLs.actuals_nontradepayables}>
            <NonTradePayablesPage />
          </Route> */}
          <Route exact path={Route_URLs.actuals_nonControllablePayables}>
            <NonControllablePayablesPage />
          </Route>
          <Route exact path={Route_URLs.actuals_nonTradeReceivables}>
            <NonTradeReceivablePage />
          </Route>
          <Route exact path={Route_URLs.profile}>
            <ProfilePage />
          </Route>
          <Route exact path={Route_URLs.customers}>
            <CustomersListing />
          </Route>
          <Route exact path={Route_URLs.suppliers}>
            <SuppliersListing />
          </Route>
          <Route exact path={Route_URLs.tearsheet_customer}>
            <TearSheetPageCustomer />
          </Route>
          <Route exact path={Route_URLs.tearsheet_supplier}>
            <TearSheetPageSupplier />
          </Route>

          {/* <Route exact path={Route_URLs.setup_connect_integration}>
            <QuickbooksConnection />
          </Route> */}
          <Route
            path={`${Route_URLs.setup_connect_integration}/:param(oracle|qbo|bc)?`}
          >
            <Connection />
          </Route>
          <Route exact path={Route_URLs.setup_general_settings}>
            <GeneralSettingsPage />
          </Route>
          <Route exact path={Route_URLs.setup_cash_classification}>
            <CashClassificationPage />
          </Route>
          <Route exact path={Route_URLs.setup_user_management}>
            <UserManagementPage />
          </Route>
          <Route exact path={Route_URLs.setup_customer_notification}>
            <CustomerNotificationPage />
          </Route>
          <Route exact path={Route_URLs.setup_dispute_management}>
            <DisputeManagementPage />
          </Route>
          <Route exact path={Route_URLs.setup_supplier_payment}>
            <SupplierPaymentPage />
          </Route>
          <Route exact path={Route_URLs.notifications}>
            <NotificationsPage />
          </Route>
          <Route exact path={Route_URLs.setup_digital_payments}>
            <DigitalPayments />
          </Route>
          <Route exact path={Route_URLs.billingAddress}>
            <BillingAddress />
          </Route>
          <Route exact path={Route_URLs.transactions_customer}>
            <Transactions />
          </Route>
          <Route exact path={Route_URLs.successPaymentPage}>
            <SuccessPaymentPage />
          </Route>
          <Route exact path={Route_URLs.paymentProcessing}>
            <PaymentProcessing />
          </Route>
          <Route exact path={Route_URLs.redirectPage}>
            <RedirectPage />
          </Route>
          <Route exact path={Route_URLs.payment_page}>
            <PaymentPage />
          </Route>
          {/* <Route exact path={Route_URLs.nontrade}>
            <NontradePage />
          </Route> */}
          <Route exact path={Route_URLs.receivable_transactions_page}>
            <ReceivableTransactions />
          </Route>
          <Route exact path={Route_URLs.guest_payment_page}>
            <GuestPayment />
          </Route>
          <Route exact path={Route_URLs.guest_payment_success_page}>
            <GuestSuccessPaymentPage />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
