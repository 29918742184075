import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { postAllSelectedAccounts, addCashClassificationBudgeting, deleteCashClassificationBudgeting, postAllSelectedRecurringAccounts } from "services/cashClassification.services";

export const useAddCashClassificationAccounts = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => postAllSelectedAccounts(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("CashClassificationAccounts");
    },
  });
};

export const useAddCashClassificationRecurringPayables = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => postAllSelectedRecurringAccounts(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("CashClassificationAccounts");
    },
  });
};

export const useAddCashClassificationBudgeting = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => addCashClassificationBudgeting(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("cash-clssification-budgeting");
    },
  });
};
export const useDeleteCashClassificationBudgeting = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => deleteCashClassificationBudgeting(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("cash-clssification-budgeting");
    },
  });
};
