import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { BasicButton } from "ui/Buttons";
import customersServices from "services/customers.services";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { Route_URLs } from "routesURLs";
import { GeneratePaymentPageReceipt } from "mutations/updateCustomers";
import NumberFormat from "react-number-format";
import PaymentReceipt from "utils/pdftemplates/paymentReceipt";
import { pdf } from "@react-pdf/renderer";
import styled from "styled-components";
import successImg from '../../assets/images/Paymen_Success_Icon.png'
import processingImg from '../../assets/images/Processing_Icon.png'
import simpliLogo from '../../assets/SimpliCapital-logo.png'

const SuccessPage = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
    margin: 20px;`
    

const SuccessPageContainer = styled.div`
background:rgb(31, 31, 33);
    padding: 60px 30px;
    border-radius: 10px;
    `
const PaymentSections = styled.div`
    
    background: #303440;
    padding: 20px;
    border-radius: 8px;
    margin-bottom:20px
    `

const DiscoverySections = styled.div`
margin-top: 20px
`
const TextSections = styled.p`
color: #DEEAF3;
font-weight:600;
margin-bottom: 20px;
font-size: 14px
`
const LogoSections = styled.div`
margin-bottom:20px`

const SuccessPaymentPage = () => {
  const transactionId = sessionStorage.getItem("transactionId");
  const email = sessionStorage.getItem("email");
  const name = sessionStorage.getItem("name");
  const amount = sessionStorage.getItem("amount");
  let timestamp = sessionStorage.getItem("timestamp");
  const status = sessionStorage.getItem("status");
  const transactionType = sessionStorage.getItem("transactionType");
  const customerId = sessionStorage.getItem("customerId");
  const tenant = sessionStorage.getItem('tenant')
  const [loading, setLoading] = useState(true);
  let type;
  timestamp = parseInt(timestamp);
  const history = useHistory();


  let dataSet = {
    transactionId: transactionId,
    tenant: tenant
  }
  const receptData =  GeneratePaymentPageReceipt(dataSet)
  useEffect(()=>{
   
  },[receptData])
  if (transactionType == "ach") {
    type = "ACH";
  } else if (transactionType == "Credit") {
    type = "Credit Card";
  } else if (transactionType == "Debit") {
    type = "Debit Card";
  } else if (transactionType == "classic_ach") {
    type = "Classic ACH";
  }
  const handleDownload = async () => {
    // const url = await customersServices.downloadPdf(transactionId, customerId);

    // console.log('transaction data ', res);
    const doc = <PaymentReceipt paymentData={receptData.data} />;

    // const link = document.createElement("a");

    // Generate a blob from the PDF document
    const asPdf = pdf(); // Initialize the pdf() generator
    asPdf.updateContainer(doc); // Load the document into the pdf container

    // Convert the document to a blob
    const blobdata = await asPdf.toBlob();
    // link.href = url;
    const url = URL.createObjectURL(blobdata);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Transaction_${receptData.data.transactionId}.pdf`; // Specify the filename for the downloaded file
    link.click();
    // Clean up the URL object
    URL.revokeObjectURL(url);
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(Route_URLs.signin);
      }
    };
  }, [history]);
  let sanitizedAmount = receptData?.data?.baseAmount ? receptData?.data?.baseAmount : receptData?.data?.amount
  console.log("typeof", typeof sanitizedAmount)
  sanitizedAmount = /^(\d{1,3})(,\d{3})*(\.\d+)?$/?.test(sanitizedAmount) && typeof sanitizedAmount !== "number" ? sanitizedAmount?.replace(/,/g, "") : sanitizedAmount
  return (
    <SuccessPage >
    {receptData?.isLoading ? 
          <>
            <Spinner animation="border" style={{ color: "#fff" }}></Spinner>
          </> :
      <SuccessPageContainer >
     <PaymentSections >
     <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
  
                {status === "APPROVED" ? (
  
                
                  <div className="paymeny-approved">
                    <div>
                      <img className="approved-img" src={successImg} alt="" />
                    </div>
                    <h2
                    style={{
                      color: "#39BC09",
                      fontWeight: "600",
                      fontSize: "35px",
                      padding: "0px 0px",
                    }}
                  >
                    Payment Success!
                  </h2>
                  </div>
                ) : (
  
                  <div className="paymeny-approved">
                    <div>
                      <img className="approved-img" src={processingImg} alt="" />
                    </div>
                    <h2
                    style={{
                      color: "#FFC000",
                      fontWeight: "600",
                      fontSize: "35px",
                      padding: "0px 0px",
                    }}
                  >
                      Processing!
                  </h2>
                  </div>
  
                  // <h2
                  //   style={{
                  //     color: "#d5d51e",
                  //     fontWeight: "600",
                  //     fontSize: "34px",
                  //     padding: "0px 25px",
                  //   }}
                  // >
                     
  
                  // </h2>
  
  
                )}
              </div>
  
              <div
                style={{
                  color: "grey",
                  paddingTop: "10px",
                  display: "grid",
                  justifyContent:"center",
                  alignItems:"center"
                }}
              >
                <table style={{marginBottom:"20px"}}>
                  <tr>
                    <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
                      Payment Method
                    </td>
                    <td
                      style={{
                        textAlign: "end",
                        color: "#909090",
                        fontWeight: "600",
                        fontSize:"14px"
                      }}
                    >
                      {receptData?.data?.pm}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
                      TransactionId
                    </td>
                    <td
                      style={{
                        textAlign: "end",
                        color: "#909090",
                        fontWeight: "600",
                        fontSize:"14px"
                      }}
                    >
                      {receptData?.data?.transactionId}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
                    Transaction Date
                    </td>
                    <td
                      style={{
                        textAlign: "end",
                        color: "#909090",
                        fontWeight: "600",
                        fontSize:"14px"
                      }}
                    >
                      {receptData?.data?.paidOn}
                    </td>
                  </tr>
                  {receptData?.data?.partialPaid ? <tr >
                    <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
                      Balance Due
                    </td>
                    <td  style={{
                     textAlign: "end",
                     color: "#909090",
                     fontWeight: "600",
                     fontSize:"14px"
                   }}>
                      <NumberFormat
                 value={parseFloat(receptData?.data?.balanceDue).toFixed(2)}   thousandSeparator={true}
                 prefix={"$"} displayType="text"
                 isNumericString={true}
                 decimalScale={2}
                  />
                  
                    </td>
                  </tr>:""}
               
                 { receptData?.data?.surcharge === true || receptData?.data?.partialPaid ?
                 <>
                  <tr>
                 {receptData?.data?.partialPaid ? <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
                   Amount Paid
                 </td>:
                   <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
                   Amount
                 </td>}
                 <td
                   style={{
                     textAlign: "end",
                     color: "#909090",
                     fontWeight: "600",
                     fontSize:"14px"
                   }}
                 >
                  <NumberFormat
                 value={parseFloat(sanitizedAmount).toFixed(2)}   thousandSeparator={true}
                 prefix={"$"} displayType="text"
                 isNumericString={true}
                 decimalScale={2}
                  />
                   
                 </td>  
               
               </tr>
               {receptData?.data?.surchargeAmount &&<tr>
               <td style={{ fontWeight: "600", textAlign: "left",fontSize:"14px" }}>
               Processing Fees ({receptData?.data?.surcharge_rate}%) 
                 </td>
                 <td
                   style={{
                     textAlign: "end",
                     color: "#909090",
                     fontWeight: "600",
                     fontSize:"14px"
                   }}
                 >
                      <NumberFormat
                 value={parseFloat(receptData?.data?.surchargeAmount).toFixed(2)}   
                 thousandSeparator={true}
                 prefix={"$"} 
                 displayType="text"
                 isNumericString={true}
                 decimalScale={2}
                  />
                 </td>
               </tr>}
               <tr>
            
               <td style={{ fontWeight: "600", textAlign: "left", color:"#C4C6C6" ,fontSize:"14px"}}>
                   Total 
                 </td>
                 <td
                   style={{
                     textAlign: "end",
                     color: "#C4C6C6",
                     fontWeight: "600",
                     fontSize:"14px"
                   }}
                 >
                      <NumberFormat
                 value={receptData?.data?.amount}   thousandSeparator={true}
                 prefix={"$"} displayType="text"
                 isNumericString={true}
                 decimalScale={2}
                  />
                 </td>
               </tr>
               
                 </>
                 
                 :
                 <tr>
                    <td style={{ fontWeight: "600", textAlign: "left", color:"#C4C6C6",fontSize:"14px"}}>
                      Total 
                    </td>
                    <td
                      style={{
                        textAlign: "end",
                        color: "#C4C6C6",
                        fontWeight: "600",
                        fontSize:"14px"
                      }}
                    >
                      ${receptData?.data?.amount}
                    </td>
                  </tr>}
                  <tr></tr>
             
                </table>
                <BasicButton
                  onClick={handleDownload}
                  style={{
                    background:
                      "#0081C5",
                    width: "98%",
                    borderRadius:"20px",
                    fontSize:"14px"
                  }}
                >
                  Generate Receipt
                </BasicButton>
                {
                  transactionType === "ach" ? (
                    <div
                      style={{
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "13px",
                        padding: "12px 35px 5px 35px",
                      }}
                    >
                      *Transactions done through Plaid will be processed within 2 business days.
                    </div>
                  ) : transactionType === "classic_ach" ? (
                    <div
                      style={{
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "13px",
                        padding: "12px 35px 5px 35px",
                      }}
                    >
  
                      *Transactions will be processed within 7 business days.
                    </div>
                  ) : ""
                }
  
  
                <p style={{ textAlign: "center", marginTop: "15px",fontSize:"12px" }}>
                Copyright &copy;{new Date().getFullYear()} SimpliCapital. All rights Reserved{" "}
                </p>
              </div>
        </PaymentSections>
        <hr style={{color:"#909090"}}/>
        <DiscoverySections >
          <LogoSections>
            <img src={simpliLogo} alt="simplicapital-logo" />
          </LogoSections>
          <TextSections>
                Liked your payment experience? <br/>
                To learn how SimpliCapital can streamline <br/>
                payments for your customers, click below:
          </TextSections>
  
                  <a href="https://calendly.com/simplicapital/" target="_blank">
          <BasicButton
                 
                  style={{
                    background:
                      "#0081C5",
                    width: "90%",
                    height:"43px",
                    borderRadius:"20px",
                    fontSize:"14px",
                  }}
                >
                  Book a Discovery Call
                  </BasicButton>
  
                  </a>
        </DiscoverySections>
     </SuccessPageContainer>}
  
      </SuccessPage>
  );
};

export default SuccessPaymentPage;
