import React from "react";
import styled from "styled-components";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Route_URLs } from "routesURLs";
import { Authorise } from "helpers/Authorization";
import { useState } from "react";
import Table from "ui/table";
import useDebounce from "hooks/useDebounce";

import moment from "moment";
import NumberFormat from "react-number-format";
import { ExportIcon, ReceivablesIcon } from "assets/icons.js";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import downloadPdf from "assets/download.gif";
import { UseTransactionList } from "queries/actuals";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";

// import { StyledDropDown, TableSection } from "./Styles";
import customersServices from "services/customers.services";
import { FilterSection, StyledDropDown, TableSection } from "../Styles";
import actualServices from "services/actual.services";
import TransactionFilter from "screens/customer/TransactionFilter";
import Tooltip from "ui/Tooltip";
import PaymentReceipt from "utils/pdftemplates/paymentReceipt";
import { useGeneralSettings } from "queries/generalSettings";
import { pdf } from "@react-pdf/renderer";

const StyledTable = styled.div`
  table {
    tr {
      &:hover {
        input {
          border: 1px solid #ffffff !important;
        }
      }
    }
    input {
      background: transparent;
      border: 0;
      color: #b5b5b5;
      text-align: center;
      width: 79px;
    }
  }
`;

export default function ReceivableTransactions() {
  const pay = useGeneralSettings();
  const [initialPage, setinitialPage] = useState(0);

  let tokenData = sessionStorage.getItem("userToken");
  let decodedData = jwt_decode(tokenData);

  const rowClick = (e, row, editId) => {
    return null;
  };

  const [filterData, setFilterData] = useState({
    limit: "",
    searchString: "",
    deliveryDueDate: "",
    minAmount: "",
    maxAmount: "",
    bucket: "",
    pageNumber: 0,
    order: "desc",
    columnName: "TransactionTimeStamp",
    customerId: decodedData.username,
    invoiceNumber: "",
    Type: "",
  });
  const user = {
    firstName: "",
    lastName: "",
  };
  const handlePageChange = (page) => {
    filterData.pageNumber = page;
    setFilterData({ ...filterData, ...filterData });
    setinitialPage(page);
  };

  const updateFilters = (updatedFilterData) => {
    if (updatedFilterData.searchString == "") {
      handlePageChange(0);
    }
    if (filterData.searchString == "" && updatedFilterData.searchString != "") {
      handlePageChange(0);
    }
    let newFilterState = { ...filterData, ...updatedFilterData };
    setFilterData(newFilterState);
  };
  const onHeaderClick = (column) => {
    filterData.columnName = column.id;
    filterData.order = filterData.order === "desc" ? "asc" : "desc";
    let updatedFilter = { ...filterData, ...filterData };

    setFilterData(updatedFilter);
  };

  const debouncedfilterData = useDebounce(filterData, 1000);
  const {
    data: transactionData,
    isLoading: isloading,
    refetch: refetchData,
  } = UseTransactionList(debouncedfilterData);
  useEffect(() => {


    transactiondata.map((value)=>{
      let invoiceAmount = 0
    if(value?.partialPay ){
      value.partialPaymentDetails.map((amount)=>invoiceAmount+=amount.originalAmount )
      value["totalOriginalAmount"] = invoiceAmount
    }
    })
  }, [transactionData, isloading]);

  let transactiondata = transactionData
    ? transactionData.data.transactions
    : [];

  useEffect(() => {}, [transactionData, isloading]);
  const totalcount = transactionData ? transactionData?.data?.totalRecords : "";

  const handleDownload = async (transactionId) => {
    const url = await actualServices.exportInvoice(transactionId);

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const handlePaymentReceiptDownload = async (transactionId) => {
    const res = await actualServices.GetTenantPdfreceiptData(transactionId);
    // console.log('transaction data ', res);
    const doc = <PaymentReceipt paymentData={res} />;

    // Generate a blob from the PDF document
    const asPdf = pdf(); // Initialize the pdf() generator
    asPdf.updateContainer(doc); // Load the document into the pdf container

    // Convert the document to a blob
    const blobdata = await asPdf.toBlob();
    console.log('blog: ', blobdata);
    
    // Trigger the file download using file-saver
    // const blob = new Blob(byteArrays, { type: "application/pdf" });
    const url = URL.createObjectURL(blobdata);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Transaction_${transactionId}.pdf`; // Specify the filename for the downloaded file
    link.click();
    // Clean up the URL object
    URL.revokeObjectURL(url);
    // document.body.removeChild(link);
  };
  // const [time, setTime] = useState(Date.now());

  // useEffect(() => {
  //   const interval = setInterval(() => setTime(Date.now()), 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  // function isWithin30Seconds(time) {
  //   const inputTime = moment(time);

  //   const now = moment();

  //   const durationInSeconds = now.diff(inputTime, "seconds");

  //   return Math.abs(durationInSeconds) <= 30;
  // }

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction Id",
        accessor: "transactionId",
        id: "transactionId",
        width: 150,
        minWidth: 130,
        Cell: (props) => (
          <>
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginRight: "5px" }}
                  onClick={(e) => {
                    handlePaymentReceiptDownload(
                      props.row.original.transactionId,
                    );
                  }}
                >
                  {props.row.original.TransactionStatus === "APPROVED" ||
                  props.row.original.TransactionStatus === "PENDING" ||
                  props.row.original.TransactionStatus === "SUCCESS" ||
                  props.row.original.TransactionStatus === "UPDATE" ? (
                    <>
                 
                        <ExportIcon
                          font-size="20px"
                          style={{ marginRight: "10px" }}
                        />
                     
                    </>
                  ) : (
                    ""
                  )}
                </span>
                {props.row.original.transactionId}
              </div>
            </>
          </>
        ),
      },
      {
        Header: "Invoice No. / Reference No.",
        accessor: (d) => {
          return d;
        },
        id: "invoiceArray",
        width: 100,
        minWidth: 80,
        Cell: ({ value }) => {
          if (value?.invoiceArray && value?.invoiceArray?.length > 0) {
            return (
              <div>
                {value.invoiceArray.map((id, index) => (
                  <div key={index}>{id}</div>
                ))}
              </div>
            );
          } else {
            return <div>{value?.referenceNumber}</div>;
          }
        },
        disableSortBy: true,
      },
      {
        Header: "Payment Method",
        accessor: "Type",
        id: "Type",
        width: 110,
        minWidth: 80,
      },

      {
        Header: "Date",
        accessor: "TransactionTimeStamp",
        id: "TransactionTimeStamp",
        width: 100,
        minWidth: 80,
        accessor: (d) => {
          return moment(d.TransactionTimeStamp).format("MM/DD/YYYY");
        },
      },
      {
        Header: "Amount",
        id: "totalOriginalAmount",
        width: 150,
        minWidth: 100,
        disableSortBy: true,


        Cell:(props)=>{

              let amount = props?.row?.original?.invoiceAmount ?props?.row?.original?.partialPay?props?.row?.original?.totalOriginalAmount:  props.row.original.invoiceAmount : props.row.original.TotalAmount
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
      
              <NumberFormat
                thousandSeparator={true}
                prefix={"$"}
                value={parseFloat(amount).toFixed(2)}
                displayType="text"
                isNumericString={true}
                decimalScale={2}
              />

            </div>
          );
            
        }
      },
      {
        Header: "Amount Paid",
        id: "TotalAmount",
        width: 150,
        minWidth: 100,
        Cell:(props)=>{
     
              let amount = props?.row?.original?.invoiceAmount ? props.row.original.invoiceAmount : props.row.original.TotalAmount
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
      
              <NumberFormat
                thousandSeparator={true}
                prefix={"$"}
                value={parseFloat(amount).toFixed(2)}
                displayType="text"
                isNumericString={true}
                decimalScale={2}
              />
          
            </div>
          );
            
        }
      },
      {
        Header: "Processing Fees",
        accessor: "surcharge",
        id: "surcharge",
        width: 100,
        minWidth: 80,
        disableSortBy: true,
        Cell:(props)=>{
          // let amount = props?.row?.original?.invoiceAmount ? props.row.original.invoiceAmount : props.row.original.TotalAmount
          return(
            <NumberFormat
            thousandSeparator={true}
            prefix={"$"}
            value={props.row.original.surcharge ? parseFloat(props.row.original.surcharge).toFixed(2):0}
            displayType="text"
            isNumericString={true}
            decimalScale={2}
          />
          )
        }
      },
      {
        Header: "Charged",
        accessor: "invoiceAmount",
        id: "invoiceAmount",
        disableSortBy: true,
        width: 100,
        minWidth: 80,
        Cell:(props)=>{
          // let amount = props?.row?.original?.invoiceAmount ? props.row.original.invoiceAmount : props.row.original.TotalAmount
          return(
            <NumberFormat
            thousandSeparator={true}
            prefix={"$"}
            value={parseFloat(props.row.original.TotalAmount).toFixed(2)}
            displayType="text"
            isNumericString={true}
            decimalScale={2}
          />
          )
        }
      },

      {
        Header: "Status",
        accessor: "TransactionStatus",
        id: "TransactionStatus",
        width: 100,
        minWidth: 80,
      },
    ],
    [pay]
  );

  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col sm={3} className="p-0">
                <StyledDropDown
                  variant="secondary"
                  align="end"
                  title="Transactions"
                >
                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplicollect",
                      "SimpliCollect&Simplipay",
                      "read-only",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_receivables}>
                      Receivables
                    </Dropdown.Item>
                  </Authorise>
                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplipay",
                      "SimpliCollect&Simplipay",
                      "read-only",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_payables}>
                      Payables
                    </Dropdown.Item>
                  </Authorise>
                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplicollect",
                      "SimpliCollect&Simplipay",
                      "read-only",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_collections}>
                      Collections
                    </Dropdown.Item>
                  </Authorise>

                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplipay",
                      "SimpliCollect&Simplipay",
                      "read-only",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_payments}>
                      Payments
                    </Dropdown.Item>
                  </Authorise>
                </StyledDropDown>
              </Col>
              <Col></Col>
            </Row>
          </Page.Header>
          <Page.Body>
            <Row style={{ display: "flex" }}>
              <FilterSection
                style={{
                  position: "relative",
                  bottom: "30px",
                  margin: "4px -27px -40px",
                  justifyContent: "end",
                }}
              >
                <TransactionFilter handleFilterData={updateFilters} />

                {/* <div className="icon-div">
                <a
                  onClick={async () => {
                    let link = await customersServices.getallcustomersDetails(
                      filterData
                    );
                    window.open(link, "_blank");
                  }}
                >
                  <ReceivablesIcon data-tip="Export" data-for="export" />
                  <Tooltip id="export" place="top" />
                </a>
              </div> */}
              </FilterSection>
            </Row>
            <TableSection>
              <Col className="p-0">
                <StyledTable>
                  {transactiondata?.length >= 0 && (
                    <Table
                      data={transactiondata}
                      columns={columns}
                      onHeaderClick={onHeaderClick}
                      totalCount={totalcount}
                      handlePageChange={handlePageChange}
                      initialPage={initialPage}
                      rowClick={rowClick}
                      isloading={isloading}
                    />
                  )}
                </StyledTable>
              </Col>
            </TableSection>
          </Page.Body>
        </Page>
      </PrivateLayout>
    </>
  );
}
