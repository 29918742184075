import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Nav as BSNav } from "react-bootstrap";
import Logo from "assets/SimpliCapital-logo.png";
import CollapsedLogo from "assets/logo.png";
import {
  Forecast,
  NetCashIcon,
  OrdersIcon,
  ScenarioIcon,
  SettingIcon,
  ReceivablesIcon,
  ArrowExpandIcon,
  TopArrowSubmenuIcon,
  DollarIcon,
} from "assets/icons.js";
import { Link } from "react-router-dom";
import { QuickBookConnectionContext } from "context/QuickBookConnectionContext";
import { Route_URLs } from "routesURLs";
import { forFutureRelease } from "utils/Configs";
import { getUserIntegrations } from "../services/user.service";
import { getOracleDataFetchStatus } from "../services/ornet.services";
import { Authorise } from "helpers/Authorization";
import { useProfile } from "queries/profile";
import { useGeneralSettings } from "queries/generalSettings";

const StyledSidebar = styled.div`
  background: #3a3b48;
  box-shadow: 0px 3px 16px #0000001d;
  position: fixed;
  display: block;
  padding: 0;
  z-index: 5;
  height: 100%;
  padding: 20px;
  /* overflow: auto; */
  width: ${(props) => (props.collapsed ? "85px" : "211px")};
  transition: all 0.2s ease-in;
  z-index: 55555;
  top: 0;

  ${({ disableTransitionEffects }) =>
    disableTransitionEffects &&
    `
   transition: none !important;
  `}

  .nav-link.active svg {
    fill: #33c2df;
  }

  .nav-text {
    opacity: ${(props) => (props.collapsed ? "0" : "1")};
    display: ${(props) => (props.collapsed ? "none" : "inline-block")};
    width: ${(props) => (props.collapsed ? "0" : "63%")};
    transition: opacity 0.7s ease-in;
  }

  .toggle-button {
    transform: ${(props) =>
      props.collapsed ? "rotate(0deg)" : "rotate(180deg)"};
    transition: transform 0s;

    svg {
      width: 41px;
      height: 41px;
    }
  }

  .indicator-icon,
  .submenu > div {
    display: ${(props) => (props.collapsed ? "none" : "block")};
  }

  .sub-menu {
    .nav-link {
      display: ${(props) => (props.collapsed ? "none" : "block")};
    }
  }
  .logo {
    display: ${(props) => (props.collapsed ? "none" : "block")};
  }
  .collapsed-logo {
    display: ${(props) => (props.collapsed ? "block" : "none")};
  }

  .indicator-icon {
    opacity: ${(props) => (props.collapsed ? "0" : "1")};
  }
`;

const SidebarShadow = styled(StyledSidebar)`
  position: relative;
  height: 1px;
  z-index: 555;
  top: 0;
  left: 0px;
  background: #3a3b48;
`;

const StyledLogo = styled.div`
  img {
    width: 100%;
  }
  margin-bottom: 36px;
`;
const StyledCollapsedLogo = styled.div`
  img {
    width: auto;
  }
  margin-bottom: 36px;
`;

const Nav = styled(BSNav)`
  flex-direction: column;

  svg {
    margin-right: 10px;
    font-size: 13px;
  }

  a.nav-link {
    text-align: left;
    font-family: Montserrat Alternates-semiBold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 1.3rem;
    padding: 0.4rem 1rem;
    margin-left: 10x;
    z-index: 1;
  }
`;

const ExpandIcon = styled.div`
  position: absolute;
  //right: 0;
  //left: 0;
  bottom: 0;
  //text-align: center;
  width: 77%;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 50px;
  z-index: 2;

  .icon {
    cursor: pointer;
    z-index: 2;
    position: absolute;
  }
`;

const SubmenuContent = styled.div`
  padding-left: 8px;
  overflow: hidden;
`;

const SubmenuCaption = styled.div`
  color: #fff;
  font-family: Montserrat Alternates-semiBold;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;
`;

const StyledSubmenu = styled.div`
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;

  .indicator-icon {
    float: right;
    font-size: 1em;
    margin-top: ${(props) => (props.activeLink ? "11px" : "2px")};
    transform: ${(props) => (props.open ? "rotateZ(0deg)" : "rotateZ(90deg)")};
    transition: all 0.2s ease-in-out;
  }

  a.nav-link {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  ${SubmenuContent} {
    transition: all 0.5s ease-in-out;
    margin-top: ${(props) => (props.open ? "12px" : "0")};
    height: ${(props) => (props.open ? "auto" : "0")};
  }
`;

const LockOverlay = styled.div`
  background: transparent;
  cursor: not-allowed;
  width: 100%;
  display: block;
  position: absolute;
  height: 380px;
  z-index: 9999999;
`;
const ActiveLink = styled.span`
  width: 4px;
  height: 20px;
  display: inline-block;
  background-color: #33c2df;
  position: relative;
  left: ${(props) => (props.collapsed ? "65%" : "45%")};
  top: 7px;
`;
const StyledIcon = styled.div`
  display: inline-block;
  svg {
    path {
      fill: ${(props) => (props.active ? "#33C2DF" : "#A5A4BF")};
    }
  }
`;

const Submenu = (props) => {
  const sidebarCollapsed = props.sidebarCollapsed;
  const [open, setOpen] = useState(!sidebarCollapsed);
  const { isQuickbookDataImported } = useContext(QuickBookConnectionContext);

  // useEffect(()=>{
  //   console.log("general--->",generalSettingsData)
  // },[isLoading])
  const toggleMenuOpen = (event) => {
    setOpen(!open);
    event.preventDefault();
    event.stopPropagation();
  };

  const shouldBeOpen = () => {
    if (sidebarCollapsed) {
      return false;
    } else {
      return open;
    }
  };

  useEffect(() => {
    if (isQuickbookDataImported) {
      setOpen(false);
    } else {
      setOpen(JSON.parse(window.sessionStorage.getItem("open" + props.title)));
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("open" + props.title, open);
  }, [open]);

  return (
    <StyledSubmenu activeLink={props.activeLink} open={shouldBeOpen()}>
      <SubmenuCaption>
        <TopArrowSubmenuIcon className="indicator-icon" />
        <Link to={props.to}>{props.icon}</Link>
        <span className="nav-text" onClick={toggleMenuOpen}>
          {props.title}
        </span>
        {props.activeLink}
      </SubmenuCaption>
      <SubmenuContent>{props.children}</SubmenuContent>
    </StyledSubmenu>
  );
};

function Sidebar(props) {
  const { data: generalSettingsData, isLoading: loading } =
    useGeneralSettings();
  const [collapsed, setCollapsed] = useState(true);
  const [disableTransitionEffects, setDisableTransitionEffects] =
    useState(true);
  // useEffect(()=>{

  // },[loading])
  const toggleSidebar = () => {
    setDisableTransitionEffects(false);
    setCollapsed(!collapsed);
  };

  const { isQuickbookDataImported } = useContext(QuickBookConnectionContext);
  const { data: userData, isLoading } = useProfile();
  const userRole = userData?.role;

  const activeRoute = (routeUrl) => {
    if (routeUrl === window.location.pathname) return true;
    return false;
  };

  useEffect(() => {
    if (isQuickbookDataImported) {
      setCollapsed(true);
    } else {
      setCollapsed(JSON.parse(window.sessionStorage.getItem("collapsed")));
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("collapsed", collapsed);
  }, [collapsed]);

  /* adding custom logic for disabling all pages if oracle tenant has not integrated oracle connection */

  useEffect(async () => {
    const connectionResponse = await getUserIntegrations();
    if (connectionResponse.data.name == "oracle") {
      let response = await getOracleDataFetchStatus();
      if (response.data.status) {
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
    } else if (
      connectionResponse.data.name == "qbo" ||
      connectionResponse.data.name == "qbd"
    ) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, []);

  return (
    <>
      {isQuickbookDataImported && <LockOverlay />}

      <SidebarShadow
        {...props}
        collapsed={collapsed}
        disableTransitionEffects={disableTransitionEffects}
      />
      <StyledSidebar
        {...props}
        collapsed={collapsed}
        disableTransitionEffects={disableTransitionEffects}
      >
        <StyledLogo className="logo">
          <a href={Route_URLs.dashboard}>
            <img alt="SimpliCapital" src={Logo}></img>
          </a>
        </StyledLogo>
        <StyledCollapsedLogo className="collapsed-logo">
          <a href={Route_URLs.dashboard}>
            <img alt="SimpliCapital" src={CollapsedLogo}></img>
          </a>
        </StyledCollapsedLogo>
        <Nav {...props}>
          <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={["admin", "read-only"]}
          >
            <Nav.Link as={Link} to={Route_URLs.netcash}>
              <StyledIcon active={activeRoute(Route_URLs.netcash)}>
                <NetCashIcon />
              </StyledIcon>
              <span className="nav-text">SimpliCash</span>
              {activeRoute(Route_URLs.netcash) && (
                <ActiveLink collapsed={collapsed} />
              )}
            </Nav.Link>
          </Authorise>
          {/* <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={["admin"]}
          >  <Nav.Link as={Link} to={Route_URLs.orders_overview}>
              <StyledIcon active={activeRoute(Route_URLs.orders_overview)}>
                <OrdersIcon />
              </StyledIcon>

              <span className="nav-text">Orders</span>
              {activeRoute(Route_URLs.orders_overview) && (
                <ActiveLink collapsed={collapsed} />
              )}
            </Nav.Link>
          </Authorise> */}
          <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={[
              "admin",
              "simplicollect",
              "SimpliCollect&Simplipay",
              "read-only",
            ]}
          >
            {" "}
            <Submenu
              className="sub-menu"
              sidebarCollapsed={collapsed}
              to={
                userRole == "simplipay"
                  ? Route_URLs.actuals_payables
                  : Route_URLs.actuals_receivables
              }
              icon={
                <StyledIcon
                  active={
                    activeRoute(Route_URLs.actuals_receivables) ||
                    activeRoute(Route_URLs.actuals_collections) ||
                    activeRoute(Route_URLs.forecast_receivables) ||
                  activeRoute(Route_URLs.forecast_collections) ||
                    activeRoute(Route_URLs.receivable_transactions_page)
                  }
                >
                  <ReceivablesIcon />
                </StyledIcon>
              }
              activeLink={
                (activeRoute(Route_URLs.actuals_receivables) ||
                  activeRoute(Route_URLs.actuals_collections) |
                  activeRoute(Route_URLs.forecast_receivables) ||
                  activeRoute(Route_URLs.forecast_collections) ||
                  
                  activeRoute(Route_URLs.receivable_transactions_page)) && (
                  <ActiveLink collapsed={collapsed} />
                )
              }
              title="SimpliCollect"
            >
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplicollect",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link as={Link} to={Route_URLs.actuals_receivables}>
                  <span className="nav-text">Receivables</span>
                </Nav.Link>
              </Authorise>
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplicollect",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link as={Link} to={Route_URLs.actuals_collections}>
                  <span className="nav-text">Collections</span>
                </Nav.Link>
              </Authorise>
              {!loading && generalSettingsData?.isPaymentEnable && (
                <Authorise
                  showUnAuthorisedComponenet={<></>}
                  allowedRoles={[
                    "admin",
                    "simplicollect",
                    "SimpliCollect&Simplipay",
                    "read-only",
                  ]}
                >
                  <Nav.Link
                    as={Link}
                    to={Route_URLs.receivable_transactions_page}
                  >
                    <span className="nav-text">Transactions</span>
                  </Nav.Link>
                </Authorise>
              )}

              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplicollect",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link as={Link} to={Route_URLs.forecast_receivables}>
                  <span className="nav-text">Receivables Forecast</span>
                </Nav.Link>
              </Authorise>
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplicollect",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link as={Link} to={Route_URLs.forecast_collections}>
                  <span className="nav-text">Collections Forecast</span>
                </Nav.Link>
              </Authorise>

              {/* )} */}
            </Submenu>
          </Authorise>

          <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={[
              "admin",
              "simplipay",
              "SimpliCollect&Simplipay",
              "read-only",
            ]}
          >
            {" "}
            <Submenu
              className="sub-menu"
              sidebarCollapsed={collapsed}
              to={
                userRole == "simplipay"
                  ? Route_URLs.forecast_payables
                  : Route_URLs.forecast_receivables
              }
              icon={
                <StyledIcon
                  active={
                  
                    activeRoute(Route_URLs.forecast_payables) ||
                    activeRoute(Route_URLs.actuals_payments) ||
                    activeRoute(Route_URLs.actuals_payables)
                    
                    
                  }
                >
                  <Forecast />
                </StyledIcon>
              }
              activeLink={
                (
                
                  activeRoute(Route_URLs.forecast_payables) ||
                  activeRoute(Route_URLs.actuals_payments)||
                  activeRoute(Route_URLs.actuals_payables)
                  ) && (
                  <ActiveLink collapsed={collapsed} />
                )
              }
              title="SimpliPay"
            >
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplipay",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link as={Link} to={Route_URLs.actuals_payables}>
                  <span className="nav-text">Payables</span>
                </Nav.Link>
              </Authorise>

              {/* {forFutureRelease && ( */}
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplipay",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link href={Route_URLs.actuals_payments}>
                  <span className="nav-text">Payments</span>
                </Nav.Link>
              </Authorise>
              {/* {forFutureRelease && ( */}
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={[
                  "admin",
                  "simplipay",
                  "SimpliCollect&Simplipay",
                  "read-only",
                ]}
              >
                <Nav.Link as={Link} to={Route_URLs.forecast_payables}>
                  <span className="nav-text">Payment Optmization</span>
                </Nav.Link>
              </Authorise>
              {/* )} */}
            </Submenu>
          </Authorise>
          <Nav.Link as={Link} to={Route_URLs.scenario}>
            <StyledIcon active={activeRoute(Route_URLs.scenario)}>
              <ScenarioIcon />
            </StyledIcon>
            <span className="nav-text">SimpliPlan</span>
            {activeRoute(Route_URLs.scenario) && (
              <ActiveLink collapsed={collapsed} />
            )}
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={
              userRole == "simplicollect" ||
              userRole == "SimpliCollect&Simplipay"
                ? Route_URLs.setup_customer_notification
                : userRole == "simplipay"
                ? Route_URLs.setup_supplier_payment
                : Route_URLs.setup_general_settings
            }
          >
            <StyledIcon active={activeRoute(Route_URLs.setup_general_settings)}>
              <SettingIcon />
            </StyledIcon>
            <span className="nav-text">Settings</span>
            {activeRoute(Route_URLs.setup_general_settings) && (
              <ActiveLink collapsed={collapsed} />
            )}
          </Nav.Link>
        </Nav>
        <ExpandIcon className="toggle-button">
          <ArrowExpandIcon className="icon" onClick={toggleSidebar} />
        </ExpandIcon>
      </StyledSidebar>
    </>
  );
}
export default Sidebar;
