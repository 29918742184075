import BaseService from "./baseClass.services";
import { apiURL } from "utils/base-url";
import moment from "moment";
import axios from "utils/APIv2";

class CustomerService extends BaseService {
  getcustomerListing = async (filterData) => {
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerName: filterData.searchString,
      averageDaysToPay: filterData.averageDaysToPay
    };
    const res = await this.axios.get(`/customers/listing`, { params });
    return res.data;
  };

  getCustomersListExport = (filterData) => {
    return `${apiURL}/customers/export?customerName=${filterData.searchString
      }&key=${sessionStorage.getItem("userToken")}`;
  };
  getallCustomerName = async (filterData) => {

    const res = await this.axios.get(`/customers/getallCustomerName`);
    return res.data;
  };
  getcustomerTrans = async (filterData) => {


    const startTimestamp =
      (filterData?.TransactionDate && filterData?.TransactionDate[0] && filterData?.TransactionDate && filterData?.TransactionDate[0] != 'Invalid Date')
        ? moment(filterData?.TransactionDate[0]).format("YYYY-MM-DD")
        : "";

    const endTimestamp =
      (filterData?.TransactionDate && filterData?.TransactionDate[1] && filterData?.TransactionDate && filterData?.TransactionDate[1] != 'Invalid Date')
        ? moment(filterData?.TransactionDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerId: filterData.customerId ? filterData.customerId : "",
      startDate: startTimestamp,
      endDate: endTimestamp,
      invoiceNumber: filterData.invoiceNumber,
      type: filterData.Type,
      TransactionStatus: filterData.TransactionStatus,
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
    };
    const res = await this.axios.get(`/customers/transaction-list`, { params });
    return res.data;
  };

  downloadPdf = async (transactionId, customerId) => {

    return `${apiURL}/digital-payment/invoice/export?transactionId=${transactionId}&customerId=${customerId}`;

  }

  getallcustomersDetails =async (filterData) => {

    const startTimestamp =
      filterData?.TransactionDate && filterData?.TransactionDate[0]
        ? moment(filterData?.TransactionDate[0]).format("YYYY-MM-DD")
        : "";

    const endTimestamp =
      filterData?.TransactionDate && filterData?.TransactionDate[0]
        ? moment(filterData?.TransactionDate[1]).format("YYYY-MM-DD")
        : "";
    let type = filterData.Type ? filterData.Type : ""
    let TransactionStatus = filterData.TransactionStatus ? filterData.TransactionStatus : ""
    let customerId = filterData.customerId ? filterData.customerId : ""
    // return `${apiURL}/customers/transaction-report/export?field=${filterData.columnName
    //   }&sort=${filterData.order}&pageNo=${filterData.pageNumber
    //   }&limit=10&customerId=${customerId}&minAmount=${filterData.minAmount
    //   }&maxAmount=${filterData.maxAmount
    //   }&startDate=${startTimestamp}&endDate=${endTimestamp}&invoiceNumber=${filterData.invoiceNumber}&type=${type}&TransactionStatus=${TransactionStatus
    //   }&key=${sessionStorage.getItem("userToken")}`;
    let res = await axios.get( `${apiURL}/customers/transaction-report/lambda/export?field=${filterData.columnName
    }&sort=${filterData.order}&pageNo=${filterData.pageNumber
    }&limit=10&customerId=${customerId}&minAmount=${filterData.minAmount
    }&maxAmount=${filterData.maxAmount
    }&startDate=${startTimestamp}&endDate=${endTimestamp}&invoiceNumber=${filterData.invoiceNumber}&type=${type}&TransactionStatus=${TransactionStatus
    }&key=${sessionStorage.getItem("userToken")}`)
    return res.data.downloadUrl
  };

  GetCustomerData = async (filterData) => {

    const res = await this.axios.get(`/customer/getcustomerdata?email=${filterData}`);
    return res.data;
  };

  EnablePaymentLink = async (filterData) => {

    const res = await this.axios.get(`customers/payment-option?userId=${filterData}`);
    return res.data;
  };

  paymentMethod = async (data) => {

    const res = await this.axios.post(`customers/payment-method`, { userId: data });
    return res.data;
  }

  transactionStatusData = async (data) => {
    
    const res = await this.axios.post(`/customers/save-transaction`, data );
    return res.data;
  }

  getCustomerInvoiceData = async (data) => {

    const res = await this.axios.post(`digital-payment/get-customer-invoice`, data);
    return res.data;
  }


  saveTransactionPaymentPage = async (data) => {
  
    const res = await this.axios.post(`digital-payment/save-transaction-payment-page`,  data );
    return res.data;
  }

  GenearteCheckSumData = async (data) => {

    const res = await this.axios.post(`digital-payment/generate-checksum`, data);
    return res.data;
  }


  GenearteSessionTokenData = async (data) => {
    if (data.amount) {
      const res = await this.axios.post(`digital-payment/generate-session-token`, data);
      return res.data;
    }

  }
  GetCustomerPdfreceiptData = async (transactionId) => {
    const params = {
      transactionId: transactionId
    };
    const res = await this.axios.get(`/customers/generate-receipt`, { params });
    return res.data;
  };
  GetPaymentPageReceiptData = async (props)=>{

    const res = await this.axios.get(`/digital-payment/invoice/export?tenant=${props.tenant}&transactionsId=${props.transactionId}`);
    return res.data;
    
  }

  getGuestPaymentDetails = async(props)=>{
    const res = await this.axios.get(`/digital-payment/guest-data?id=${props.id}`)
    return res.data
  }

  createGuestPageChecksum = async(props)=>{
    const res = await this.axios.post('/digital-payment/guest-page/generate-checksum',props)
    return res.data
  }

  createGuestPageSessionToken = async(props)=>{
    const res = await this.axios.post('/digital-payment/guest-page/generate-session-token',props)
    return res.data
  }
  saveTransactionGuestPage = async(props)=>{
    const res = await this.axios.post('/digital-payment/guest-page/save-transaction',props)
    return res.data
  }

  GetGuestPageReceiptData = async (props)=>{

    const res = await this.axios.get(`/digital-payment/invoice-guest-pay/export?id=${props.id}&transactionsId=${props.transactionId}`);
    return res.data;
    
  }

  GetGuestPageInvoice = async (props)=>{

    const res = await this.axios.post(`/digital-payment/guest-pay-invoice-data`,props);
    return res.data;
    
  }
  getCardTokenCustomer = async (props)=>{
    const res = await this.axios.get(`/customers/uniqe-id`,props)
    return res.data
  }
   saveCardTokenCustomer = async (props)=>{
    const res = await this.axios.post(`/customers/save-uniqe-id`,props)
    return res.data
  }
  cardTokenPaymentPage = async(props)=>{
   
    const res = await this.axios.get(`/digital-payment/generate-card-token?tenant=${props.tenant}`)
   
    return res.data
  }
  saveCardTokenPaymentPage = async (props)=>{
    const res = await this.axios.post(`/digital-payment/save-card-token`,props)
    return res.data
  }

}


export default new CustomerService();
