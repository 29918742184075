import React from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { updateCustomers,updateCustomersBilling } from "services/customer.services";
import customersServices from "services/customers.services";

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ customerId, values }) => updateCustomers(customerId, values),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("customers");
      },
    }
  );
};
export const useUpdateCustomerBillingDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body) => updateCustomersBilling(body),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("customers");
      },
    }
  );
};

export const GenerateSessionToken = (data) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.GenearteSessionTokenData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("sessiontoken");
      },
    }
  );
};

export const GenerateCheckSum = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.GenearteCheckSumData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("checksum");
      },
    }
  );
};


export const SaveTransactionPayment = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.saveTransactionPaymentPage(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("saveTransactionPayment");
      },
    }
  );

};

export const GetCustomerInvoice = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.getCustomerInvoiceData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("getCustomerInvoiceData");
      },
    }
  );

};

export const SaveTransactionPaymentcustomer = (data) => {

  const queryClient = useQueryClient();
  return useMutation(
    (data) => customersServices.transactionStatusData(data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("saveTransactionPayment");
      },
    }
  );

};
export const GeneratePaymentPageReceipt = (filterData) => {
  // const queryClient = useQueryClient();
 return useQuery(
    ["payment_page_receipt", { filterData }],
    () => customersServices.GetPaymentPageReceiptData(filterData),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    }
  );}

  export const GetGuestPaymentDetails = (filterData)=>{
    return useQuery(
      ['guestDetails',{filterData}],
      ()=> customersServices.getGuestPaymentDetails(filterData)
    )
  }
  export const CreateGuestPageChecksum = (filterData)=>{
   
    const queryClient = useQueryClient();
    return useMutation(
      (data) => customersServices.createGuestPageChecksum(data),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("guest_checksum");
        },
      }
    );
  }
  
  export const CreateGuestPageSessionToken = (filterData)=>{
   
    const queryClient = useQueryClient();
    return useMutation(
      (data) => customersServices.createGuestPageSessionToken(data),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("guest_sessionToken");
        },
      }
    );
  }
  
  export const SaveTransactionGuestPage = (filterData)=>{
   
    const queryClient = useQueryClient();
    return useMutation(
      (data) => customersServices.saveTransactionGuestPage(data),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries("saveTransactionGuestPage");
        },
      }
    );
  }
  export const GenerateGuestPageReceipt = (filterData) => {
    // const queryClient = useQueryClient();
   return useQuery(
      ["payment_page_receipt", { filterData }],
      () => customersServices.GetGuestPageReceiptData(filterData),
      {
        keepPreviousData: true,
        staleTime: 60 * 60 * 1000,
      }
    );
  };
  
  export const GetGuestPageInvoice = () => {
    const queryClient = useQueryClient();
   return useMutation((data)=> customersServices.GetGuestPageInvoice(data),{
    onSuccess:(res)=>{
      queryClient.invalidateQueries('guestPageInvoice');
  
    }
   }
    );
  };
  export const GetCardTokenCustomer = (token)=>{
    // const queryClient = useQueryClient()
    return useQuery(["getCardTokenCustomer",{token}],()=>customersServices.getCardTokenCustomer(token)
    ,{
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    })
  }
  
  export const SaveCardTokenCustomer = ()=>{
    const queryClient = useQueryClient();
    return useMutation((data)=>customersServices.saveCardTokenCustomer(data),{
      onSuceess:(res)=>{
        queryClient.invalidateQueries("storePaymentLogsCustomer")
      
    }})
  }

  export const CardTokenPaymentPage = (token)=>{
    // const queryClient = useQueryClient()
    console.log("token--->",token)
    return useQuery(["cardTokenPaymentPage",{token}],()=>customersServices.cardTokenPaymentPage(token)
    ,{
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    })
  }
  
  
  export const SaveCardTokenPaymentPage = ()=>{
    const queryClient = useQueryClient();
    return useMutation((data)=>customersServices.saveCardTokenPaymentPage(data),{
      onSuceess:(res)=>{
        queryClient.invalidateQueries("SaveCardTokenPaymentPage")
      
    }})
  }