import { getAllSelectedAccounts, getAllIncomeAccounts, getAllExpenseAccounts, getCashClassificationBudgetingExpenseList, getCashClassificationBudgetingIncomeList, getAllrecurringAccounts } from "services/cashClassification.services";
import { useQuery } from "react-query";

export const useCashClassificationAccounts = () => {
  return useQuery("CashClassificationAccounts", getAllSelectedAccounts);
};
export const useCashClassificationIncomeAccounts = () => {
  return useQuery(["cashClassificationIncomeAccounts"], getAllIncomeAccounts);
};
export const useCashClassificationExpenseAccounts = () => {
  return useQuery(["cashClassificationExpenseAccounts"], getAllExpenseAccounts);
};
export const useCashClassificationRecurringAccounts = () => {
  return useQuery(["useCashClassificationRecuringAccounts"], getAllrecurringAccounts);
};
export const useCashClassificationExpenseAccountsList = (payload) => {
  return useQuery(["cashClassificationExpenseAccountsList"], getCashClassificationBudgetingExpenseList(payload));
};
export const useCashClassificationIncomeAccountsList = (payload) => {
  return useQuery(["cashClassificationExpenseAccountsList"], getCashClassificationBudgetingIncomeList(payload));
};