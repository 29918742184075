export const Route_URLs = {
  /* Public Routes */
  signin: "/sign-in",
  createaccount: "/sign-up/create-account",
  forgotpassword: "/forgot-password",
  accountverify: "/account/verify",
  passwordreset: "/account/password-reset",
  mfascreen: "/account/mfa",
  customer_signup: "/sign-up/create-customer-account",
  /* Private Routes */
  dashboard: "/dashboard",
  dashboard_customer: "/dashboard/customer",
  dashboard_customize: "/dashboard/customize",
  dashboard_customer_payment_confirmed: "/dashboard/customer/payment-confirmed",
  netcash: "/simplicash",
  forecast_receivables: "/simplicollect/receivables-forecast",
  forecast_payables: "/simplipay/paymentOptimization",
  forecast_collections: "/simplicollect/collections-forecast",
  forecast_optimization: "/simplipay/optimization",
  scenario: "/simpliplan",
  credit_monitoring: "/creditMonitoring",
  orders_invoices: "/orders/invoices",
  orders_overview: "/orders/overview",
  actuals_receivables: "/simplicollect/receivables",
  actuals_nonTradeReceivables: "/simplicollect/receivables/nonTrade",
  actuals_collections: "/simplicollect/collections",
  actuals_payables: "/simplipay/payables",
  actuals_nonControllablePayables: "/simplipay/payables/nonControllable",
  actuals_payments: "/simplipay/payments",
  actuals_payment_confirmed: "/simplipay/payment-confirmed",
  profile: "/profile",
  customers: "/customers",
  suppliers: "/suppliers",
  tearsheet_customer: "/tearsheet/customer",
  tearsheet_supplier: "/tearsheet/supplier",
  setup_connect_integration: "/setup/connect-integration",
  setup_general_settings: "/setup/general-settings",
  setup_cash_classification: "/setup/cash-classification",
  setup_user_management: "/setup/user-management",
  setup_customer_notification: "/setup/customer-notification",
  setup_dispute_management: "/setup/dispute-management",
  setup_supplier_payment: "/setup/supplier-payment",
  notifications: "/notifications",
  // nontrade: "/nontrade",
  emailTemplateCreation: "/setup/emailTemplateGenerator",
  emailTemplatesList: "/setup/emailTemplatesList",

  /*operations routes */
  admin_login: "/admin/login",
  operationDashboard: "/admin/dashboard",
  tenantList: "/admin/login/tenant",
  customerList: "/admin/login/customers",
  sessionList: "/admin/login/audit",
  adminUsers: "/admin/users",
  analytics: "/admin/analytics",
  dataLoads: "/admin/loads",
  subscriptions: "/admin/subscriptions",
  featureSwitches: "/admin/switches",
  setup_digital_payments: "/setup/digital-payments",
  billingAddress: "/customer/billingAddress",
  transactions_customer: "/customer/transactions",
  successPaymentPage: "/customer/successPayment",
  paymentProcessing: "/customer/paymentProcessing",
  redirectPage: "/customer/redirectPage",
  payment_page: "/customer/paymentPage",
  receivable_transactions_page: "/simplicollect/receivables/transactions",
  guest_payment_page: "/pay/:tenantidentifier",
  guest_payment_success_page: "/guest-pay/success-page"
};
